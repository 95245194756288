import * as React from "react";
import sanityClient from "@sanity/client";
import useWindowSize from "utils/useWindowSize";

const defaultValues = {};

export const SiteContext = React.createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isSidebar, setSidebar] = React.useState();
  const [isSearch, setSearch] = React.useState();
  const [smoothCancel, setSmoothCancel] = React.useState(false);
  const [ navTheme, setNavTheme ] = React.useState();
  const [ navSolid, setNavSolid ] = React.useState();
  const [ width ] = useWindowSize();

  const client = sanityClient({
    projectId: "72ij7d0h",
    dataset: "production",
    apiVersion: "2021-10-21", // use current UTC date - see "specifying API version"!
    token: `${process.env.GATSBY_SANITY_READ_TOKEN || ""}`, // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  });

  React.useEffect(() => {}, []);

  const searchDocuments = (text) => {
    const query = `{
      "posts": *[_type == "post" && _score > 0]
        | score(
          title match $searchQuery
          || title match $searchQuery + "*"
          || title match "*" + $searchQuery
          || pt::text(subtitle) match $searchQuery
          || pt::text(subtitle) match $searchQuery + "*"
          || pt::text(subtitle) match "*" + $searchQuery
          || pt::text(pageBuilder[].content) match $searchQuery
          || pt::text(pageBuilder[].content) match $searchQuery + "*"
          || pt::text(pageBuilder[].content) match "*" + $searchQuery
          || pt::text(pageBuilder[].quote) match $searchQuery
          || pt::text(pageBuilder[].quote) match $searchQuery + "*"
          || pt::text(pageBuilder[].quote) match "*" + $searchQuery
        ) 
        | order(_score desc) [0...5]
        { _score, title, publishedAt, slug, categories[]->{title} },
      "glossary": *[_type == "definition" && _score > 0]
         | score(
          word.en match $searchQuery
          || word.en match $searchQuery + "*"
          || word.en match "*" + $searchQuery
          || definition.en match $searchQuery
          || definition.en match $searchQuery + "*"
          || definition.en match "*" + $searchQuery
        ) 
        | order(_score desc) [0...2]
        { _score, word, part, definition, id, _id }[0...2],
      "directory": *[_type == "organization" && _score > 0]
         | score(
          name match $searchQuery
          || name match $searchQuery + "*"
          || name match "*" + $searchQuery
          || accreditations[] match $searchQuery
          || accreditations[] match $searchQuery + "*"
          || accreditations[] match "*" + $searchQuery
          || roles[].label match $searchQuery
          || roles[].label match $searchQuery + "*"
          || roles[].label match "*" + $searchQuery
          || locations[].label match $searchQuery
          || locations[].label match $searchQuery + "*"
          || locations[].label match "*" + $searchQuery
          || pt::text(subTitle) match $searchQuery
          || pt::text(subTitle) match $searchQuery + "*"
          || pt::text(subTitle) match "*" + $searchQuery
          || pt::text(description) match $searchQuery
          || pt::text(description) match $searchQuery + "*"
          || pt::text(description) match "*" + $searchQuery
        ) 
        | order(_score desc) [0...5]
        { _score, name, roles, _id },
    }`;

    const params = { searchQuery: text };

    return client.fetch(query, params);
  };

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        client,
        width,
        searchDocuments,
        isSidebar,
        setSidebar,
        isSearch,
        setSearch,
        navTheme,
        setNavTheme,
        navSolid,
        setNavSolid,
        smoothCancel,
        setSmoothCancel,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
